.container-white {
  width: max-content;
  cursor: pointer;
}
.container-blue {
  width: max-content;
  color: #0783b6;
  background-color: var(--secondary-main) !important;
  cursor: pointer;
}
.feedback-name-typo-white {
  align-items: center;
  gap: 75px;
  padding: 20px 0px;
  color: #0783b6 !important;
  font-size: 18px;
  font-weight: 700 !important;
}
.feedback-content-typo-white {
  align-items: center;
  gap: 75px;
  padding: 20px 0px;
  color: #6f6f6f !important;
  font-size: 18px;
  font-weight: 500 !important;
}
.feedback-name-typo-blue {
  align-items: center;
  gap: 75px;
  padding: 20px 0px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700 !important;
}
.feedback-content-typo-blue {
  align-items: center;
  gap: 75px;
  padding: 20px 0px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500 !important;
}
