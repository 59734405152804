.footer {
  background-color: var(--secondary-main);
  padding: 30px 0;
}

.footer-link-container {
  border-bottom: 1px solid antiquewhite;
}

.footer-link-sub-container {
  justify-content: flex-start;
  margin-bottom: 25px !important;
}

.footer-title-typo {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: antiquewhite;
  margin-bottom: 8px !important;
}

.quick-links-typo {
  font-size: 17px;
  line-height: 1.75rem;
  color: antiquewhite;
}

.footer-common-typo {
  font-size: 17px;
  line-height: 1.75rem !important;
  color: antiquewhite;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px !important;
}

.contact-info-email-icon {
  height: 22px;
  margin-bottom: -5px;
  margin-right: 7px;
}

.contact-info-email-link {
  text-decoration: none;
  color: #ffffff;
}

.contact-info-whatsapp-icon {
  height: 20px;
  margin-bottom: -3px;
  margin-right: 7px;
}

.contact-info-whatsapp-link {
  text-decoration: none;
  color: #ffffff;
}

.footer-logo-container {
  margin-top: 30px !important;
}

.all-right-reserved-typo {
  font-size: 17px;
  color: antiquewhite;
}

.footer_web_link {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}
