.popular {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 5rem;
}

.collections {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 50px;
}

.popular-item{
    margin-top: 50px;
    display: flex;
    gap: 30px;
}

.title-typo {
  font-family: inherit;
  color: #ffffff;
  font-size: 45px !important;
  font-weight: 600;
  text-align-last: center;
  margin-bottom: 20px !important;
}