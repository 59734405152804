.customTabPanel {
  background-color: var(--primary-light);
}
.custom-tab-typo {
  color: #07223e;
}
.custom-panel-tab-menu-container {
  background-color: var(--primary-light);
  justify-content: center !important;
  padding: 5px 0;
}
.custom-panel-tab-menu {
  color: #ffffff !important;
  justify-content: center !important;
  padding: 10px 0;
}
.custom-panel-tab-menu-typo {
  color: #ffffff !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}

.MuiTabs-flexContainer {
  justify-content: center !important;
}
.Mui-selected {
  color: var(--secondary-main) !important;
  border-bottom: 2px solid var(--secondary-main) !important;
}
