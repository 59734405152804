.product-view-grid {
    min-height: 60vh;
}

.title-typo {
    text-align: center;
    margin-bottom: 35px !important;
    font-size: 30px !important;
    font-weight: 700;
    color: #ffffff;
}