* {
  margin: 0;
}

:root {
  --secondary-main: #166fc5;
  --primary-light: #07223e;
  --primary-main: #061728;
  --primary-dark: #03060d;
}

body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-main) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
