.new-collections {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-bottom: 100px;
  margin-top: 5rem;
}

.new-collections hr {
  width: 200px;
  height: 6px;
  border-radius: 10px;
  background: #252525;
}

.collections {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 50px;
  width: max-content;
}

.title-typo {
  font-family: inherit;
  color: #ffffff;
  font-size: 45px !important;
  font-weight: 600;
  text-align-last: center;
  margin-bottom: 20px !important;
}
