.feedback {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 5rem 0 80px 0;
}

.feedback-list {
    display: flex;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 50px;
    width: max-content;
}

.feedback-item{
    margin-top: 30px;
    display: flex !important;
    gap: 50px;
    justify-content: space-between;
}

.title-typo {
    font-family: inherit;
    color: #ffffff;
    font-size: 45px !important;
    font-weight: 600;
    text-align-last: center;
    margin-bottom: 20px !important;
  }