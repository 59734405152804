.carousel {
  height: calc(100vh - 90px);
  width: 100%;
  display: flex;
}

.carousel_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel_card {
  display: flex;
  flex: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
  opacity: 0;
  pointer-events: none;
  transition: 0.5s ease-in-out;
}

.carousel_card-active {
  opacity: 1;
  transform: scale(1);
  pointer-events: visible;
}

.card_image {
  width: 100%;
  object-fit: cover;
}

.card_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 40px 30px;
  align-items: flex-end;
}

.card_title {
  color: white;
  font-size: 30px;
}

.carousel_arrow_left,
.carousel_arrow_right {
  position: absolute;
  font-size: 40px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: gainsboro;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 30px;
  border-radius: 20%;
  padding-bottom: 7px;
  cursor: pointer;
  margin: 10px;
}

.carousel_arrow_left {
  left: 15px;
}

.carousel_arrow_right {
  right: 15px;
}

.carousel_pagination {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
}

.pagination_dot {
  height: 10px;
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.pagination_dot:hover {
  transform: scale(1.2);
}

.pagination_dot-active {
  background-color: steelblue;
}

/* bubbles */
.bubble {
  height: 60px;
  width: 60px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  position: absolute;
  top: 10%;
  left: 10%;
  animation: 4s linear infinite;
}

.bubble .dot {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 20%;
  right: 30%;
}

.bubble:nth-child(1) {
  top: 20%;
  left: 26%;
  animation: animate 8s linear infinite;
}
.bubble:nth-child(2) {
  top: 60%;
  left: 80%;
  animation: animate 10s linear infinite;
}
.bubble:nth-child(3) {
  top: 40%;
  left: 40%;
  animation: animate 3s linear infinite;
}
.bubble:nth-child(4) {
  top: 66%;
  left: 30%;
  animation: animate 7s linear infinite;
}
.bubble:nth-child(5) {
  top: 90%;
  left: 10%;
  animation: animate 9s linear infinite;
}
.bubble:nth-child(6) {
  top: 30%;
  left: 60%;
  animation: animate 5s linear infinite;
}
.bubble:nth-child(7) {
  top: 70%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.bubble:nth-child(8) {
  top: 75%;
  left: 36%;
  animation: animate 10s linear infinite;
}
.bubble:nth-child(9) {
  top: 50%;
  left: 50%;
  animation: animate 6s linear infinite;
}
.bubble:nth-child(10) {
  top: 45%;
  left: 20%;
  animation: animate 10s linear infinite;
}
.bubble:nth-child(11) {
  top: 10%;
  left: 90%;
  animation: animate 9s linear infinite;
}
.bubble:nth-child(12) {
  top: 20%;
  left: 70%;
  animation: animate 7s linear infinite;
}
.bubble:nth-child(13) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.bubble:nth-child(14) {
  top: 60%;
  left: 5%;
  animation: animate 6s linear infinite;
}
.bubble:nth-child(15) {
  top: 90%;
  left: 80%;
  animation: animate 9s linear infinite;
}
.bubble:nth-child(16) {
  top: 50%;
  left: 20%;
  animation: animate 4s linear infinite;
}
.bubble:nth-child(17) {
  top: 30%;
  left: 40%;
  animation: animate 6s linear infinite;
}
.bubble:nth-child(18) {
  top: 10%;
  left: 90%;
  animation: animate 9s linear infinite;
}
.bubble:nth-child(19) {
  top: 90%;
  left: 80%;
  animation: animate 10s linear infinite;
}
.bubble:nth-child(18) {
  top: 10%;
  left: 30%;
  animation: animate 9s linear infinite;
}
@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(70deg);
  }
  100% {
    transform: scale(1.3) translateY(-100px) rotate(360deg);
  }
}
