.active {
  color: var(--secondary-main);
  font-weight: 600 !important;
}

nav {
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-main);
  color: white;
  padding: 1rem 2rem;
  box-shadow: 0 2px 3px -2px var(--primary-light);
}
/*start - logo */

.nav-logo img {
  height: 60px;
}
@media screen and (max-width: 765px) {
  .nav-logo img {
    height: 48px;
  }
  nav {
    padding: 16px;
  }
}

.nav-logo {
  display: flex;
  align-items: center;
}
.nav-logo p {
  color: #171717;
  font-size: 38px;
  font-weight: 600;
}
/* end - logo */

.logo-name-typo {
  color: #ffffff !important;
  font-size: 30px;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  font-size: 20px;
  font-weight: 600;
}

nav ul li a:not(.active):hover {
  color: var(--secondary-main);
  font-size: 20px;
  font-weight: 600;
}
/* menu - start */
nav .menu {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 1.6rem;
  gap: 5px;
}

nav .menu span {
  height: 4px;
  width: 100%;
  background-color: #fff;
  border-radius: 0.2rem;
}
/* drawer menu */

@-webkit-keyframes fadeIn {
  from {
    transform: scaleY(0.5);
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

nav ul {
  display: flex;
  list-style: none;
  margin-left: auto;
}
@media (max-width: 480px) {
  nav ul {
    display: none;
    flex: 1 100%;
    border-radius: 8px;
    background-color: var(--primary-light);
    list-style: none;
    flex-direction: column;
    padding: 1rem 0.5rem;
    margin-top: 1rem;
  }
  nav .menu {
    display: flex;
    padding: 6px;
    cursor: pointer;
  }
  nav .open {
    display: flex;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  nav .close {
    display: none;
  }
}

/* menu - end */
