.aboutus-container
{
   padding: 50px 0;
}

.about-us-title {
    color: #ffffff !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    margin-bottom: 20px !important;
}

.about-us-content-typo {
    color: #ffffff !important;
    margin: 10px 0 !important;
    line-height: 1.5rem !important;
}
.about-us-image-container {
    margin: 30px 0;
}
.about-us-image-grid {
    padding: 10px;
}